import React, { useState, useEffect } from "react";
import {
	Button,
    ListGroup,
	ListGroupItem,
	Container,
	Card,
	CardBody, 
	CardHeader
} from "reactstrap";

import { Row, Col} from "react-bootstrap";
import {  useLocation } from "react-router-dom";
import DashboardService from "../Services/DashboardService";
import   UserContext  from '../contexts/UserContext';
import '../css/ButtonStyle.css';

function SelectDashboard() {
	const [dashboards, setdashboards] = useState([]);
	const location = useLocation();
	const { pathname } = location;
    const [selected, setSelected] = useState();
	const [loginCredentials, setLoginCredentials] = useState({
		userEmail: "",
		userPassword: "",
		customer: "",
	});
	const [fgpassCredentials, setFgpassCredentials] = useState({
		userEmail: "",
		customer: "",
	});
    const {loginUser, forgotPassword, reset, ...state } = React.useContext(UserContext);
	const {email, password} = state;

    useEffect( () => {
        console.log("Dashboards: " + state.dashboards + " email: " + email + ", password: " + password );
        setdashboards(state.dashboards);
		setLoginCredentials({...loginCredentials, userEmail: email, userPassword: password});
		setFgpassCredentials({...fgpassCredentials,userEmail : email});

    },[]); // Empty dependency array means this effect runs once after the initial render


    const handleDashboardChange = (e) => {
		e.preventDefault();
		const dashboard = e.target.value;
		setSelected(dashboard);
		setLoginCredentials({...loginCredentials,customer : dashboard} );
		setFgpassCredentials({...fgpassCredentials,customer : dashboard});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
        console.log("Dashboards handleSubmit: pathname: " + pathname );
		
		if(pathname.indexOf("login") > -1) {
			console.log("Dashboards handleSubmit: LoginCredentials - customer: " + loginCredentials.customer + ", email: " + loginCredentials.userEmail + ", password: " + loginCredentials.userPassword );

			var result = await loginUser(loginCredentials);
		} else{
			console.log("Dashboards handleSubmit: ForgotPassword - customer: " + fgpassCredentials.customer + ", email: " + fgpassCredentials.userEmail);
			var result = await forgotPassword(fgpassCredentials);
		}
		console.log(
			"SelectDashboard.js: handleSubmit ~ result:",
			result
		);
	
	};

	return (
		<Container>
			<div className="animate__animated animate__fadeIn" >
				<h1 className="text-center mb-5">Select Dashboard</h1>
				{/* <Row> */}
					<Col sm={8} className="mx-auto">
						{/* <Row> */}
							<Card  className="h-75 overflow-auto">
								<CardHeader className="custom-gray text-center"><strong>Available Dashboards</strong></CardHeader>
								<CardBody>
									<ListGroup style={{ maxHeight: '150px', marginBottom: '10px', overflowY: 'scroll' }}>
									{dashboards &&
										dashboards.map((dshb, index) => (
											<ListGroupItem
												key={index} // Use a unique identifier here if available
												active={dshb === selected}
												action
												className="animate__animated animate__fadeInDown "
												
												tag="button"
												onClick={handleDashboardChange}
												value={dshb}>
												{dshb}
											</ListGroupItem>
											))}
									</ListGroup>
								</CardBody>
							</Card>
						{/* </Row> */}
						{/* <Row className="my-3"> */}
							<Col className="text-center my-3">
								<Button 
								className="mx-auto custom-blue bordered-0"
								type="submit"
								onClick={handleSubmit}>
									OK
								</Button>
							</Col>
						{/* </Row> */}
					</Col>
				{/* </Row> */}
			</div>
		</Container>
	);
}
export default SelectDashboard;