import axios from "axios";
import BASE_URL from "../apiUrlConfig";

const API_URL_GET_DASHBOARD = BASE_URL + "api/Dashboard";

const getDashboard = async (inputs) => {
    console.log("getDashboard IdToken: " + inputs.idToken + ", Customer: " + inputs.customer + ", Email: " + inputs.email);

    const formData = new FormData();
    formData.append("idToken", inputs.idToken);
    formData.append("customer", inputs.customer);
    formData.append("email", inputs.email);

    const res = axios.post(API_URL_GET_DASHBOARD, formData,{ headers: {
        'Content-Type': 'application/json'
        }});
    return res;

}

const DashboardService = {
    getDashboard
};

export default DashboardService;
