
import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Card, CardHeader, Col } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import   UserContext  from '../contexts/UserContext';


function UpdatePasswordPage() {
	const [credentials, setCredentials] = useState({
        email:"",
		password: "",
	});
	const [changepw, setChangepw] = useState(false);

    const navigate = useNavigate();
	const {changePassword,loginUser, ...state } = React.useContext(UserContext);
	const { userId, isError, message } = state;

	const changepassword = async (e) => {
		e.preventDefault();
        
	}
    useEffect(() => {
		if (isError) {
			toast.error(message);
		}
		if(userId) {
			console.log("###ChangePassword userId: " + userId);
		}
		if(message === "Password changed"){
      toast.success(message);
			navigate("/home");
		}
		
	
	}, [userId, isError, message, navigate]);

    const [formInput, setFormInput] = useState({
        
        password: "",
        confirmPassword: "",
      });
    
      const [formError, setFormError] = useState({
        
        password: "",
        confirmPassword: "",
      });
    
      const handleUserInput = (name, value) => {
        setFormInput({
          ...formInput,
          [name]: value,
        });
      };

    const validateFormInput = (event) => {
        event.preventDefault();
        let inputError = {
            email:"",
          password: "",
          confirmPassword: "",
        };

        if (!formInput.email) {
            setFormError({
              ...inputError,
              password: "Email can not be empty",
            });
            return
          }

        if (!formInput.password) {
            setFormError({
              ...inputError,
              password: "Password should not be empty",
            });
            return
          }
      
         
          if (formInput.confirmPassword !== formInput.password) {
            setFormError({
              ...inputError,
              confirmPassword: "Password and confirm password should be same",
            });
            return;
          }
      
          if (!formInput.password) {
            setFormError({
              ...inputError,
              password: "Password should not be empty",
            });
            return;
          }
      
          console.log("formError confirmPassword" + formInput.confirmPassword);
		  console.log("#UpdatePassword email: " + formInput.email);
          //console.log("credentials userId: " + credentials.techUserId + ", Password: " + formInput.confirmPassword);
		//setCredentials({...credentials,password: formInput.confirmPassword});
          setFormError(inputError);
		  let params = {email: formInput.email, password: formInput.confirmPassword}
          changePassword(params);
	  };

return (
   
    <div className="container">
    <Col sm={5} className="mx-auto">
        <Card>
            <CardHeader className="text-center">
                <strong>Update Password</strong>
            </CardHeader>
            <Form className="mt-3" onSubmit={validateFormInput}>
            
            <FormGroup row>
                    <Label sm={6} className="text-center">
                        <strong>Email:</strong>
                    </Label>
                    <Col sm={6}>
                        <Input
                        className="mx-auto w-75 text-center"
                        type="email"
                        placeholder="Enter Email Address"
                        name="email"
                        id="email"
                        value={formInput.email}
                        onChange={({ target }) => {
                            handleUserInput(target.name, target.value);
                          }}
                                                
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                <p className="error-message">{formError.email}</p>
                </FormGroup>
                <FormGroup row>
                    <Label sm={6} className="text-center">
                        <strong>New Password</strong>
                    </Label>
                    <Col sm={6}>
                        <Input
                        className="mx-auto w-75 text-center"
                        type="password"
                        placeholder="Enter password"
                        name="password"
                        id="password"
                        value={formInput.password}
                        onChange={({ target }) => {
                            handleUserInput(target.name, target.value);
                          }}
                                                
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                <p className="error-message">{formError.password}</p>
                </FormGroup>
                <FormGroup row>
                    <Label sm={6} className="text-center">
                        <strong>Confirm Password</strong>
                    </Label>
                    <Col sm={6}>
                        <Input
                        className="mx-auto w-75 text-center"
                        type="password"
                        placeholder="Enter Confirm password"
                        name="confirmPassword"
                        id="confirmPassword"
                        value={formInput.confirmPassword}
                        onChange={({ target }) => {
                            handleUserInput(target.name, target.value);
                          }}
                        
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                <p className="error-message" color="red">{formError.confirmPassword}</p>
                </FormGroup>
                <FormGroup>
                    <Col sm={5} className="mx-auto text-center">
                    <Button
                        className="mx-auto mt-3 "
                        type="submit"
                        color="primary"
                        size="sm"
                        disabled ={formInput.password === "" ||  formInput.password !== formInput.confirmPassword}
                        >
                        Update Password
                    </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Card>
    </Col>
</div>
);
}
export default UpdatePasswordPage;
