
import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Card, CardHeader, Col, CardTitle, Container } from "reactstrap";
//import { toast } from "react-toastify";
import { Link, useNavigate , Outlet} from "react-router-dom";
import   UserContext  from '../contexts/UserContext';
import NewPasswordModal from "../components/NewPassword";
import SelectDashboard from "../components/SelectDashboard";
import LoadingSpinner from "../components/LoadingSpinner";


function LoginPage() {
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [selectDashboardModal, setselectDashboardModal] = useState(false);
	const [credentials, setCredentials] = useState({
		userEmail: "",
		userPassword: "",
        customer:null,
	});
	const [changepw, setChangepw] = useState(false);
    const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const {loginUser, testLogin,  handleResetPasswordException, ...state } = React.useContext(UserContext);
	const { userId,isLoggedIn,customer, isError, message , newPassword} = state;

	const login = async (e) => {
		e.preventDefault();
        
        const fetchData = async () => {
            setLoading(true);
            // get the data from the api
            const response = await loginUser(credentials);
            setLoading(false);
            console.log("fetchData1 : ");
          }
        // call the function
         fetchData()
       
              // make sure to catch any error
             .catch(console.error);
    }
      
	

    useEffect(() => {
		console.log("LoginPage - isloggedIn: " + isLoggedIn + ", message: " + message);
		if (isError === true 
            && message !== undefined 
            && message.includes("Password reset required for the user")) 
            {
			console.error("LoginPage - Error: " + state.message);
            var creds = {userEmail: credentials.userEmail};
			handleResetPasswordException(creds);
		}
		
	}, [ isError,  message]);

return (
    <>
			{(isLoggedIn ) ? (
				<Outlet />
			) : (
    <Container>
    {loading && <LoadingSpinner />}
        <Col sm={5} className="mx-auto">
            <Card className="mx-auto mt-3">
                <CardHeader className="text-center custom-gray">
                    <strong>User Login</strong>
                </CardHeader>
                <Form className="mt-3" onSubmit={login}>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input
                                className="mx-auto  text-center w-75"
                                type="text"
                                placeholder="Enter email"
                                name="email"
                                id="email"
                                value={credentials.userEmail}
                                onChange={(e) => {
                                    setCredentials({
                                        ...credentials,
                                        userEmail: e.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Input
                            className="mx-auto  text-center w-75"
                            type="password"
                            placeholder="Enter password"
                            name="password"
                            id="password"
                            value={credentials.userPassword}
                            onChange={(e) => {
                                    setCredentials({
                                        ...credentials,
                                        userPassword: e.target.value,
                                    });
                            }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={5} className="mx-auto text-center" >
                        <Button
                            className="mx-auto custom-blue"
                            type="submit"
                            color={credentials.userEmail === "" || credentials.userPassword === "" ? 'secondary' : 'primary'}
                            disabled={credentials.userEmail === "" || credentials.userPassword === ""}>
                            Login
                        </Button>
                        </Col>
                        <Col sm={5} className="mx-auto text-center" >
                            <Link to="/forgotpassword">Forgot password?</Link>
                            </Col>
                    </FormGroup>
                </Form>
            </Card>
        </Col>
    </Container>)}
    </>
);
}
export default LoginPage;
