import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button,
	Form,
} from "reactstrap";
import AdminService from "../Services/AdminService";

function ChangeAdminModal({ modal, setModal, selectedEmail }) {

	const toggle = () => setModal(!modal);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const customer = sessionStorage.getItem('customer').replace(/"/g, '');
		const currEmail = sessionStorage.getItem('adminemail').replace(/"/g, '');
		let params = {currentAdminEmail:currEmail, 
					newAdminEmail: selectedEmail, 
					customer: customer};

		var result = await AdminService.changeAdmin(params);

		console.log("ChangeAdminModal: handleSubmit result:",result	);

		if (result.data.success === true) {
			toast.success("Admin changed successfully");
			setModal(!modal);
		} else {
			toast.error("Admin changed failed : " + result.data.message);

		}
	};

	return (
		<Modal isOpen={modal} toggle={() => setModal(!modal)}>
			<ModalHeader toggle={() => setModal(!modal)}>
				Change Admin
			</ModalHeader>
			<Form onSubmit={handleSubmit}>
				<ModalBody>
					<form>
						<div className="mb-3">
							<label htmlFor="name" className="form-label">
								Are you sure you want to make ({selectedEmail}) the new Admin?
                                There can only be one Admin, you will no longer be Admin.
                                You will automatically be logged out after selecting 'OK'.
							</label>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit">
						OK
					</Button>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
}
export default ChangeAdminModal;
