import React, { createContext, useContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminService from "../Services/AdminService";

export const AdminContext = React.createContext();



const initialState = {
    users:[],
    sites:[],
    }

    const adminReducer = (state, action) => {
        switch (action.type) {
            case "GET_USERS":
            case "DELETE_USERS":
                return {
                    ...state,
                    users: action.payload,
                };
            case "GET_SITES":
                return {
                    ...state,
                    sites: action.payload,
                };
                case "CLEAR_SITES":
                    return {
                        ...state,
                        sites:[]
                    };
                
        }
    }
    export const AdminContextProvider = ({ children }) => {

        const [state, dispatch] = useReducer(adminReducer, initialState);

        async function getUsers() {
            try {
                const cust = sessionStorage.getItem('customer').replace(/"/g, '');
                const loggedIn = sessionStorage.getItem("userEmail").replace(/"/g, '');
                let params = {customer: cust, loggedInUser: loggedIn};
                const resp = await AdminService.getUsers(params );
                dispatch({ type: "GET_USERS", payload: resp.data });
            } catch (error) {
                toast.error("Failed to get users");
            }
        }

        async function getSites(emails) {
            try {
                const cust = sessionStorage.getItem('customer').replace(/"/g, '');
                let params = {customer: cust, emails: emails};
                const resp = await AdminService.getSites(params );
                dispatch({ type: "GET_SITES", payload: resp.data });
            } catch (error) {
                toast.error("Failed to get sites");
            }
        }

        function clearSites() {
            dispatch({
                type: "CLEAR_SITES"
            });
        }
    

        async function deleteUsers(credentials) {
            try {
            const response = await AdminService.deleteUsers(credentials);
            if(response.data.success) {
                //update the user array with the deleted item(s)
                const remaining = state.users.filter(obj => !credentials.emails.includes(obj.email));
                //const remaining = state.users.filter(user => !credentials.emals.includes(user.email));
            console.log("deleteUsers: " + remaining);

                dispatch({ type: "DELETE_USERS", payload: remaining});
            }
            return response;
            }
            catch (err) {
                toast.error("Failed to delete user(s)");
            }
        }

        const value = { ...state, dispatch, getUsers,getSites,deleteUsers,clearSites};
        return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
    };

    export default AdminContext;
