import React, { useState, useContext, useEffect } from 'react';
import {
  Navbar,
  Nav,
  Button,
  Collapse,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Tooltip,
  Over
} from 'reactstrap';
import { FiLogIn, FiLogOut } from 'react-icons/fi';
import UserContext from '../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import '../css/NavMenu.css';

export function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
    const { loginUser, logoutUser,launchDashboard, ...state } = useContext(UserContext);
  const { isLoggedIn, isAdmin , dashboardUrl,loading} = state;
  const navigate = useNavigate();
  
  const handleUserState = () => {
    console.log('handleUserState: ' + isLoggedIn);
    if (isLoggedIn) {
      logoutUser();
      navigate('/login');
    } else {
      navigate('/login');
    }
  };

  const handleRefresh= async(e) => {
		e.preventDefault();
        launchDashboard();
    }
  
  const adminEmail = sessionStorage.getItem('adminemail');

  useEffect(() => {
    console.log('NavMenu - isAdmin: ' + isAdmin);
  }, [isAdmin]);

return (
  <div>
    <div>
      <header>
            <img src='/npi-logo.png' alt='Logo' className='navbar-logo mt-2' />
            <Navbar
              className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow' 
              style={{ background: '#7d98ab' }}
              dark>
               <NavbarBrand href="/">
                  <img src='/Onsight_Logo.png' alt="OnsightLogo" style={{ height: '40px', width: 'auto' }} />
               </NavbarBrand>
              <NavbarToggler onClick={toggleNavbar} className='mr-2' />
              <Collapse
                className='d-sm-inline-flex flex-grow-0'
                isOpen={collapsed}
                navbar>
                <Nav navbar className='me-auto'>
                  <NavItem>
                    {isLoggedIn && isAdmin && (
                      <NavLink 
                      style={{ color: 'black' }}
                       tag={Link} to='/users'>
                        Config Users{' '}
                      </NavLink>
                    )}
                  </NavItem>
                  {(dashboardUrl !== "") && (
                  <Button
                  variant="info"
                  onClick={handleRefresh}
                >
                  {'Click to reload'}
                </Button>
                )}
                 
                </Nav>

                <Button
                  id='LoginLogoutButton'
                  style={{ color: 'black' }}
                  className='fs-5 bg-transparent border-0'
                  onClick={handleUserState}>
                    
                  {isLoggedIn ? <FiLogOut /> : <FiLogIn />}
                </Button>
              </Collapse>
            </Navbar>
      </header>
    </div>
  </div>
);
}
