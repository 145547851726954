import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

function ConfirmationModal({ modal, setModal, title, message, onConfirm }) {
	return (
		<Modal isOpen={modal} toggle={() => setModal(!modal)}>
			<ModalHeader className="custom-gray"  toggle={() => setModal(!modal)}>{title}</ModalHeader>
			<ModalBody>{message}</ModalBody>
			<ModalFooter>
				<Button color="primary" className="custom-blue bordered-0" onClick={onConfirm}>
					Confirm
				</Button>
				<Button color="secondary" onClick={() => setModal(!modal)}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default ConfirmationModal;
