import React, { useState, useEffect } from "react";
import { Button, ListGroup,ListGroupItem, ListGroupItemText,Container, Card,CardBody, CardHeader, Spinner } from "reactstrap";
import { Row, Col} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddUserModal from "../components/AddUserModal";
import AdminService from "../Services/AdminService";
import ChangeAdminModal from "../components/ChangeAdminModal";
import UserContext  from '../contexts/UserContext';
import ConfirmationModal from "../components/ConfirmationModal";
import '../css/ButtonStyle.css'

function AdminPage(){
    const [addUserModal, setAddUserModal] = useState(false);
    const [changeAdminModal, setChangeAdminModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);

    const [addCredentials, setAddCredentials] = useState({
        customer:"",
		username: "",
		email: "",
	});

    const [dshCredentials, setdshCredentials] = useState({
		email: "",
        customer:null,
        idToken:null,
	});
    const {launchDashboard, ...state } = React.useContext(UserContext);
    const[selectedEmail, setSelectedEmail] = useState("");
    const[selectedUser, setSelectedUser] = useState("");
    const [userList, setUserList] = useState([]);


    const launch= async(e) => {
		e.preventDefault();
        launchDashboard();
    }

    //handle click event for Add USer button
    const handleAddUser = async (e) => {
		e.preventDefault();
        console.log("handleAddUser : " );
        setAddUserModal(!addUserModal); 
	}

    //call back of AddUserModal dialog
    const onAddUser = (value) => {
        setSelectedEmail(value.email);
        setSelectedUser(value.userName);
        console.log("onAddUser : email - " + value.email + " , userName - " + value.userName);
        //add new user in the userList
        setUserList([...userList, value]);
    }

    const OnDeleteUser = async (e) => {
		e.preventDefault();
        console.log("OnDeleteUser : " );
        setConfirmationModal(!confirmationModal); 
	}

    const handleDeleteUser = async (e) => {
		e.preventDefault();
        const customer = sessionStorage.getItem('customer').replace(/"/g, '');
        console.log("handleDeleteUser : customer - " + customer + " , selectedEmail - " + selectedEmail);

		let params = {email: selectedEmail, customer: customer};
        var result = await AdminService.deleteUser(params);
		console.log("handleDeleteUser result:",result);
        
		if (result.data.success === true) {
            toast.success("User deleted successfully");
            deleteItemFromList();
		} else {
            toast.error("Failed to delete user - " + result.data.message);
		}
        setConfirmationModal(!confirmationModal);
	}

    // Function to delete an item from the list
    const deleteItemFromList = () => {
        // Filter out the item to be deleted
        const updatedList = userList.filter(item => item.email !== selectedEmail);
        // Update the state with the filtered list
        setUserList(updatedList);
    };

    const handleChangeAdmin = async (e) => {
		e.preventDefault();
        console.log("handleChangeAdmin : " );
        setChangeAdminModal(!changeAdminModal); 
       // navigate("/adduser");
	}

    useEffect(() => {

        const fetchData = async () => {
            console.log("Admin customer: " + sessionStorage.getItem('customer'));
            const customer = sessionStorage.getItem('customer').replace(/"/g, '');
            //get list of users from server
            const response = await AdminService.getUsers(customer);
            return response;
        }
        // call the function
        fetchData()
        .then(response =>{
            const result = JSON.stringify(response.data);
            console.log("fetchData AdminPage : " + result );
            setUserList(response.data);

        })
             // make sure to catch any error
            .catch(console.error);
        },[]);

    const handleEmailChange = (e) => {
        e.preventDefault();
        // get string after the colon
        const selectedEmail = JSON.parse(e.currentTarget.value).email;
        const selectedUser = JSON.parse(e.currentTarget.value).userName;
        console.log("User selected : " + selectedEmail + ", username: " + selectedUser);
        setSelectedEmail(selectedEmail);
        setSelectedUser(selectedUser);
    };

    return (
        <Container className="text-center d-flex justify-content-center mx-auto pt-4" fluid="sm">
            <AddUserModal
				modal={addUserModal}
				setModal={setAddUserModal}
                onAddUser={onAddUser}
			/>
            <ChangeAdminModal
				modal={changeAdminModal}
				setModal={setChangeAdminModal}
                selectedEmail = {selectedEmail}
			/>
            <ConfirmationModal
				modal={confirmationModal}
				setModal={setConfirmationModal}
                title="Delete User"
				message="Are you sure you want to delete this user?"
				onConfirm={handleDeleteUser}
			/>

            <div className="animate__animated animate__fadeIn" >
                <Row>
                    <Col sm={12} className="mx-auto">
                        <Row>
                        <Card style={{ maxHeight: "75%" }} className="h-75 overflow-auto">
                            <CardHeader><strong>Manage Users</strong></CardHeader>
                            <CardBody>
                                <ListGroup>
                                {userList &&
                                        userList.map((user) => (
                                            <ListGroupItem
                                            active={user.email === selectedEmail}
                                                action
                                                className="animate__animated animate__fadeInDown"
                                                tag="button"
                                                key={user.userName}
                                                onClick={handleEmailChange}
                                                value={JSON.stringify(user)}>
                                                <ListGroupItemText className="mr-auto">{user.userName} ({user.email})</ListGroupItemText>
                                            </ListGroupItem>
                                        ))}
                                </ListGroup>
                            </CardBody>
                        </Card>
                        </Row>
                        <Row className="my-3 justify-content-between">
                            <Col sm="auto" className="text-center">
                                <Button onClick={handleAddUser} className="me-2 custom-blue">Add</Button>
                                <Button 
                                    onClick={OnDeleteUser}
                                    disabled={userList.length > 1 && selectedEmail && selectedEmail.length > 1 ? false : true}
                                    color={userList.length > 1 && selectedEmail && selectedEmail.length > 1 ? 'danger' : 'secondary'}
                                >
                                    Delete
                                </Button>
                            </Col>
                            <Col sm="auto" className="text-center">
                                <Button 
                                    onClick={handleChangeAdmin}
                                    disabled={userList.length > 1 && selectedEmail && selectedEmail.length > 1 ? false : true}
                                    color={userList.length > 1 && selectedEmail && selectedEmail.length > 1 ? 'primary' : 'secondary'}
                                >
                                    Change Admin
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col  className=" my-auto mx-auto text-center">
                            <Button color="success"
                                onClick={launch}>
                                    Launch Dashboard
                            </Button>
                        
                    </Col>
                </Row>
            </div>
        </Container>
	);
}
export default AdminPage;