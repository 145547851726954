import React, { createContext, useContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminService from "../Services/AdminService";

export const AssignmentContext = React.createContext();

const initialState = {
	siteAssignments: {
		checkedSites: [],
		uncheckedSites: [],
		emails: [],
	},
	
};

// for setting assignments, send filled out object to reducer
const reducer = (state, action) => {
	switch (action.type) {
		case "CLEAR_ASSIGNMENTS":
			return {
				...state,
				siteAssignments: {
					checkedSites: [],
					uncheckedSites: [],
					emails: [],
				},
			};
		case "SET_SITE_ASSIGNMENTS":
			return {
				...state,
				siteAssignments: {
					...state.siteAssignments,
					...action.payload,
				},
			};
		case "SET_CHECKED_SITES":
			return {
				...state,
				siteAssignments: {
					...state.siteAssignments,
					checkedSites: [
						...state.siteAssignments.checkedSites,
						action.payload,
					],
				},
			};
		case "REMOVE_CHECKED_SITES":
			return {
				...state,
				siteAssignments: {
					...state.siteAssignments,
					checkedSites: state.siteAssignments.checkedSites.filter(
						(site) => site !== action.payload
					),
				},
			};
		case "REMOVE_UNCHECKED_SITES":
			return {
				...state,
                siteAssignments: {
					...state.siteAssignments,
					uncheckedSites: state.siteAssignments.uncheckedSites.filter(
						(site) => site !== action.payload
					),
                    },
			};
		case "SET_UNCHECKED_SITES":
			return {
				...state,
				siteAssignments: {
					...state.siteAssignments,
					uncheckedSites: [
						...state.siteAssignments.uncheckedSites,
						action.payload,
					],
				},
			};
		case "SET_EMAILS":
			return {
				...state,
				siteAssignments: {
					...state.siteAssignments,
					emails: action.payload,
				},
			};
		case "CLEAR_EMAILS":
			return {
				...state,
				siteAssignments: {
					...state.siteAssignments,
					emails: [],
				},
			};
		default:
			return state;
	}
};
export const AssignmentContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	function setSiteAssignments(assignments) {
		dispatch({
			type: "SET_SITE_ASSIGNMENTS",
			payload: assignments,
		});
	}

	function setCheckedOrUncheckedSite(site) {
		if (
			site.checked &&
			!state.siteAssignments.checkedSites.includes(site.id)
		) {
			dispatch({
				type: "SET_CHECKED_SITES",
				payload: site.id,
			});
			if (state.siteAssignments.uncheckedSites.includes(site.id)) {
				dispatch({
					type: "REMOVE_UNCHECKED_SITE",
					payload: site.id,
				});
			}
		} else if (site.checked === false || site.checked === 0) {
			if (state.siteAssignments.uncheckedSites.includes(site.id)) {
				return;
			}
			if (state.siteAssignments.checkedSites.includes(site.id)) {
				dispatch({
					type: "REMOVE_CHECKED_SITES",
					payload: site.id,
				});
			}
			dispatch({
				type: "SET_UNCHECKED_SITES",
				payload: site.id,
			});
		}
	}

  
	async function setSiteAssignmentsForUsers() {
		console.log(
			"AssignmentsContext.js:134 ~ setSiteAssignmentsForUsers ~ state.siteAssignments:",
			state.siteAssignments
		);
		try {
			const response = await AdminService.setSiteAssignmentsForUsers(
				state.siteAssignments
			);
			if (response.data) {
				toast.success("Sites assigned successfully");
				return true;
			}
		} catch (error) {
			console.log(
				"AssignmentsContext.js: setSiteAssignmentsForUsers ~ error",
				error
			);
			toast.error("Error assigning sites");
			return false;
		}
	}

	

	const value = {
		...state,
		setSiteAssignments,
		setCheckedOrUncheckedSite,
		setSiteAssignmentsForUsers,
		
	};

	return (
		<AssignmentContext.Provider value={value}>
			{children}
		</AssignmentContext.Provider>
	);
};

export default AssignmentContextProvider;
