import React from 'react';
import {CardHeader, Container, Row, Col,Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';

function InfoCard({ title, message, onClick }) {
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={4}>
    <Card>
    <CardHeader className="custom-gray">
    Dashboard Response
  </CardHeader>
      <CardBody>
        <CardTitle tag='h5'>{title}</CardTitle>
        <CardText>{message}</CardText>
        <Button className="custom-blue" onClick={onClick}>OK</Button>
      </CardBody>
    </Card>
    </Col>
    </Row>
    </Container>
  );
}

export default InfoCard;
