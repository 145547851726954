import React from "react";
import { FaGear } from "react-icons/fa6";
import '../css/Loading.css';

export default function LoadingSpinner() {
	return (
		<div className="position-absolute top-50 start-50 translate-middle">
			<FaGear
				className="text-customBlue spinning-gear"
				style={{ fontSize: "96px" , color: "#1ba5c0" }}
			/>
		</div>
	);
}