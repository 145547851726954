
import React, { useEffect, useState, useContext} from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import moment from 'moment'
import { SessionTimeoutModal } from './SessionTimeoutModal'
import {  UserContext } from '../contexts/UserContext';

export function  SessionTimeoutHandler(props){
    const[showModal,setShowModal]=useState(false);
    const[userLoggedIn,setUserLoggedIn]=useState(false);
    const { isLoggedIn, logoutUser } = useContext(UserContext);
    const navigate = useNavigate();
    let timer=undefined;
    const events= ['click','load','keydown']
    const eventHandler =(eventType)=>{
        
        console.log(eventType);
        console.log("SessionTimeoutHandler userLoggedIn: " + userLoggedIn);
        if(userLoggedIn) {
            sessionStorage.setItem('lastInteractionTime',moment() )
            if(timer){
                props.onActive();
               
                startTimer();
            }
        }
    };
    
    useEffect(()=>{
        //add event only if user has logged in , otherwise he/she can stay on home page as long as they want
        if(isLoggedIn === true) {
            setUserLoggedIn(true);  
        }
        addEvents();
        return (()=>{
            removeEvents();
            clearTimeout(timer);
        })
    },[isLoggedIn])
    
    const startTimer=()=>{
        if(timer){
            clearTimeout(timer)
        }
        timer=setTimeout(()=>{
            
            let lastInteractionTime=sessionStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval=props.timeOutInterval?props.timeOutInterval:300000;

            if(isLoggedIn === false){
                clearTimeout(timer);
            }else{
                if(diff._milliseconds<timeOutInterval){
                    startTimer();
                    props.onActive();
                }else{
                    props.onIdle();
                    setShowModal(true)
                }
            }
            
        },props.timeOutInterval?props.timeOutInterval:300000)
        
    }
    const addEvents=()=>{
        
        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })
        
        startTimer();
    }
    
    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };
    
    const handleContinueSession = ()=>{

        setShowModal(false)
        setUserLoggedIn(true)
    }
    const handleLogout = ()=>{

        removeEvents();
        clearTimeout(timer);
        setUserLoggedIn(false)
        props.onLogout();
        setShowModal(false);
        if(isLoggedIn){
            logoutUser();
            navigate("/login");
        }
        
    }
    
    return(
        <div>
        
        <SessionTimeoutModal 
        showModal={showModal} 
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
        />
        
        </div>
        )
        
    }