import React, { useRef, useEffect } from "react";

const updateInput = (ref, checked) => {
	const input = ref.current;
	if (input) {
		input.checked = checked;
		input.indeterminate = checked === null;
	}
};

export const CustomCheckbox = ({ checked, onChange }) => {
	const checkBoxRef = useRef(null);
	const checkedRef = useRef(checked);

	useEffect(() => {
		checkedRef.current = checked;
		updateInput(checkBoxRef, checked);
	}, [checked]);

	// checkbox can start out null but not be set to null by clicking
	const handleClicked = () => {
		switch (checkedRef.current) {
			case true:
				checkedRef.current = false;
				break;
			default:
				checkedRef.current = true;
				break;
		}
		updateInput(checkBoxRef, checkedRef.current);
		if (onChange) {
			onChange(checkedRef.current);
		}
	};
//adding class - "form-check-input" adds blue color to the checkbox
	return <input ref={checkBoxRef} type="checkbox" class="form-check-input " onClick={handleClicked} />;
};
