import React, { useState, useEffect, useContext, useReducer } from 'react';
import UserContext from '../contexts/UserContext';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import InfoCard from '../components/InfoCard';

import { Container, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

const initialState = {
	dshModal: false,

};
const reducer = (state, action) => {
	switch (action.type) {
		case "DSHWARN_MODAL":
			return {
				...state,
				dshModal: action.payload,
			};
	
		default:
			return state;
	}
};


const Dashboards = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { ...context} = React.useContext(UserContext);
  const { loading,logoutUser, isLoggedIn,message,isAdmin,urlResponse, dashboardUrl } = context;
  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none'
  };
  const navigate = useNavigate();

  const onConfirm = () => {
    console.log("Dashboard onConfirm");
    if (isLoggedIn) {
      logoutUser();
      navigate('/login');
    } else {
      navigate('/login');
    }
  }

  const onConfirmByAdmin = () => {
    console.log("Dashboard onConfirmByAdmin");
    if (isLoggedIn) {
      navigate('/users');
    }
  }


  useEffect(() => {

    if (!isLoggedIn) {
      navigate('/login');
      }
  }, []);

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading ? (
        urlResponse === 1 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="iframe-container">
            <iframe
              src={dashboardUrl}
              style={iframeStyle}
              title='QuickSight Dashboard'
            ></iframe>
          </div>
        </div> 
        ) : message === null ? (
          <InfoCard
          title="Dashboard Unavailable"
          message= "We apologize, but the dashboard is currently unavailable. Please check back later or contact support for assistance."
          onClick={onConfirm}
        />
        ) : (
          
          <InfoCard
          title="Warning"
          message={message}
          onClick={isAdmin ? onConfirmByAdmin : onConfirm}
        />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboards;
