import axios from "axios";
import BASE_URL from "../apiUrlConfig";

const API_URL = BASE_URL + "api/Login/";
const API_URL_LOGIN = API_URL; //"https://localhost:7275/api/Login";
const API_URL_CHANGE_PASSWORD = API_URL+ "changepassword"; //"https://localhost:7275/api/Login/login/changepassword";
const API_URL_RESET_PASSWORD = API_URL+ "resetpassword";  //"https://localhost:7275/api/Login/resetpassword";
const API_URL_UPDATE_PASSWORD = API_URL+ "updatepassword";  //"https://localhost:7275/api/Login/updatepassword";
const API_URL_FORGOT_PASSWORD = API_URL+ "forgotpassword";  //"https://localhost:7275/api/Login/forgotpassword";
const API_URL_USERPOOL_USERS = API_URL+ "users/";  //"https://localhost:7275/api/Login/users/";

// Login user
const loginUser = async (credentials) => {

    const formData = new FormData();
        formData.append("useremail", credentials.userEmail);
        formData.append("userpassword", credentials.userPassword);
        if(credentials.customer !== null){
            formData.append("customer", credentials.customer);
        }

    const res = await axios.post(API_URL_LOGIN, formData,{ headers: {
        'Content-Type': 'application/json'
        }});

        return res;
}

const updatePassword = async (credentials) => {
    console.log("1. updatePassword password - " + credentials.password);
    const formData = new FormData();
    formData.append("session", credentials.session);
    formData.append("clientid", credentials.clientId);
    formData.append("email", credentials.email);
    formData.append("password", credentials.password);

    const res = await axios.post(API_URL_UPDATE_PASSWORD, formData,{ headers: {
        'Content-Type': 'application/json'
        }});
    return res;
}

//Change password
const changePassword = async(credentials) => {
    console.log("1. changePassword password - " + credentials.password);

    const formData = new FormData();
    formData.append("userId", credentials.userId);
    formData.append("password", credentials.password);

    const res = await axios.post(API_URL_CHANGE_PASSWORD, formData,{ headers: {
        'Content-Type': 'application/json'
        }});
    return res;

};

const emails = async(credentials) => {

}

//Reset password
const resetPassword = async(credentials) => {
    console.log("Reset password - email: " + credentials.email + " customer: " + credentials.selectedCustomer);

    const formData = new FormData();
    formData.append("email", credentials.email);
    formData.append("customer", credentials.selectedCustomer);
    formData.append("confirmationcode", credentials.confirmationcode);
    formData.append("password", credentials.password);

    const res = await axios.post(API_URL_RESET_PASSWORD, formData,{ headers: {
        'Content-Type': 'application/json'
        }});
    return res;
};

const forgotPassword = async(credentials) => {
    console.log("forgotPassword password - email: " + credentials.userEmail); 
    const formData = new FormData();
    formData.append("email", credentials.userEmail);
    if(credentials.customer !== null){
        formData.append("customer", credentials.customer);
    }
    const res = await axios.post(API_URL_FORGOT_PASSWORD,formData,
        { 
        headers: {
            'Content-Type': 'application/json'
            }
        }
    );
    return res;

};

const getUsers = async(cust) => {
    console.log("getUsers for - " + cust);
    const res = await axios.get(API_URL_USERPOOL_USERS+cust);
    console.log("getUsers response - " + JSON.stringify( res.data));
    return res;
}

const LoginService = {
	loginUser,
	changePassword,
	resetPassword,
    updatePassword,
    forgotPassword,
    getUsers,
    emails
};

export default LoginService;
