import axios from "axios";
import BASE_URL from "../apiUrlConfig";

const API_URL = BASE_URL + "api/Admin/";
const API_URL_EDIT_USER = API_URL + "edituser";  //"https://localhost:7275/api/Admin/edituser";
const API_URL_ADD_USER = API_URL + "adduser";  //"https://localhost:7275/api/Admin/adduser";
const API_URL_DELETE_USER = API_URL + "deleteuser";  //"https://localhost:7275/api/Admin/deleteuser";
const API_URL_DELETE_USERS = API_URL + "deleteusers";  //"https://localhost:7275/api/Admin/deleteusers";
const API_URL_GET_USERS = API_URL + "users/";  //"https://localhost:7275/api/Admin/users/";
const API_URL_GET_SITES = API_URL + "sites/";  //"https://localhost:7275/api/Admin/sites/";
const API_URL_SET_SITES = API_URL + "assignsites/";  //"https://localhost:7275/api/Admin/assignsites/";
const API_URL_CHANGE_ADMIN = API_URL + "changeadmin";  //"https://localhost:7275/api/Admin/changeadmin";


//get list of users for a customer
const getUsers = async(req) => {
    console.log("getUsers for - " + req.customer + ", " + req.loggedInUser );
    const formData = new FormData();
    formData.append("customer", req.customer);
    formData.append("loggedInUser", req.loggedInUser);
    const res = axios.post(API_URL_GET_USERS,formData,{ headers: {
                                'Content-Type': 'application/json'
                            }});
    console.log("getUsers response - " + JSON.stringify( res.data));
    return res;
}

//get list of sites for a customer 
const getSites = async(req) => {
    console.log("getUsers for - " + req.customer + ", " + req.emails );
    const formData = new FormData();
    formData.append("customer", req.customer);
    req.emails.forEach(email => {
        formData.append("emails[]", email); // Append each email as an array parameter
    });
    const res = axios.post(API_URL_GET_SITES,formData,{ headers: {
                                'Content-Type': 'application/json'
                            }});
    console.log("getSites response - " + JSON.stringify( res.data));
    return res;
}


// add new user
const addUser = async (credentials) => {
    console.log("addUser password: " + credentials.customer );

    const formData = new FormData();
    formData.append("customer", credentials.customer);
    formData.append("username", credentials.username);
    formData.append("email", credentials.email);
    formData.append("group", credentials.group);
    formData.append("userType", credentials.usertype);

    const res = axios.post(API_URL_ADD_USER, formData,{ headers: {
                'Content-Type': 'application/json'
                }});
    return res;
}

const editUser = async (credentials) => {

    console.log("editUser: " + credentials.customer );
    const loggedInUser = sessionStorage.getItem('userEmail').replace(/"/g, '');
    const formData = new FormData();
    formData.append("loggedInUserEmail",loggedInUser);
    formData.append("customer", credentials.customer);
    formData.append("username", credentials.username);
    formData.append("email", credentials.email);
    formData.append("group", credentials.group);
    formData.append("userType", credentials.usertype);


    //TODO: update url when it is ready
    const res = axios.post(API_URL_EDIT_USER, formData,{ headers: {
                        'Content-Type': 'application/json'
                        }});
    return res;

}

//delete user
const deleteUser = async(credentials) => {
    console.log("1. deleteUser - " + credentials.email + " , customer:  " + credentials.customer);

    const formData = new FormData();
    formData.append("email", credentials.email);
    formData.append("customer", credentials.customer);

    const res = axios.post(API_URL_DELETE_USER, formData,{ headers: {
                'Content-Type': 'application/json'
                }});
    return res;
};

//delete user
const deleteUsers = async(credentials) => {
    console.log("1. deleteUsers - " + credentials.emails + " , customer:  " + credentials.customer);

    const formData = new FormData();
            credentials.emails.forEach(email => {
            formData.append("emails[]", email); // Append each email as an array parameter
        });
        formData.append("customer", credentials.customer);

    const res = axios.post(API_URL_DELETE_USERS, formData,{ headers: {
                'Content-Type': 'application/json'
                }});
    return res;
};



const setSiteAssignmentsForUsers = (checkedAndUnchecked) => {
	try {
		var checked = [];
		var unchecked = [];
		var emails = [];
		checkedAndUnchecked.checkedSites.forEach((cs) => {
			checked.push(cs);
		});
		checkedAndUnchecked.uncheckedSites.forEach((us) => {
			unchecked.push(us);
		});
		checkedAndUnchecked.emails.forEach((m) => {
			emails.push(m);
		});

        const cust = sessionStorage.getItem('customer').replace(/"/g, '');
		const response = axios({
			method: "post",
			url: API_URL_SET_SITES,
			data: {
                Customer: cust,
				CheckedSites: checked,
				UncheckedSites: unchecked,
				Emails: emails,
			},
		});
		return response;
	} catch (error) {
		return error;
	}
};
//change Admin user
const changeAdmin = async(credentials) => {
    console.log("ChangeAdmin - Customer: " + credentials.customer +  ", currentEmail: " + credentials.currentAdminEmail + ", NewEmail: " + credentials.newAdminEmail);

    const formData = new FormData();
    formData.append("customer", credentials.customer);
    formData.append("CurrentAdminEmail", credentials.currentAdminEmail);
    formData.append("NewAdminEmail", credentials.newAdminEmail);

    const res = axios.post(API_URL_CHANGE_ADMIN, formData,{ headers: {
                        'Content-Type': 'application/json'
                        }});
    return res;
};

const AdminService = {
    getUsers,
	addUser,
	deleteUser,
    deleteUsers,
	changeAdmin,
    editUser,
    getSites,
    setSiteAssignmentsForUsers
};

export default AdminService;
