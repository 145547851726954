import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button,
	Form,
} from "reactstrap";
import AdminService from "../Services/AdminService";
import SegmentedButton from "./SegmentedButton";

function AddUserModal({ modal, setModal, onAddUser }) {
	const [credentials, setCredentials] = useState({
        email: "",
        customer:"",
        username: "",
		group:"",
		usertype:"",
      });

	const toggle = () => setModal(!modal);

	const [isValidEmail, setIsValidEmail] = useState(false);

	const handleEmailChange = (e) => {
	  const email = e.target.value;
	  setCredentials({
		...credentials,
		email: email
	  });
	  setIsValidEmail(validateEmail(email));
	};

	const validateEmail = (email) => {
		const regex = /([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
		return regex.test(email);
	};

	const areAllFieldsFilled = () => {

		const isValid = validateEmail(credentials.email);
		return credentials.email.trim() !== '' &&
				isValid &&
			   credentials.username.trim() !== '' &&
			   credentials.group.trim() !== '' &&
			   credentials.usertype.trim() !== '';
	  };

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCredentials({...credentials, customer: sessionStorage.getItem('customer') });
		const customer = sessionStorage.getItem('customer').replace(/"/g, '');
		let params = {email: credentials.email, customer: customer, username: credentials.username,group: credentials.group, usertype: credentials.usertype};

		var result = await AdminService.addUser(params);
		console.log(
			"AddUserModal.js: handleSubmit ~ result:",
			result
		);
		if (result.data.success === true) {
			toast.success("User added successfully");
			onAddUser({email: credentials.email, userName: credentials.username, group: credentials.group, userType: credentials.usertype});
			setModal(!modal);
		} else {
			toast.error("Failed to add user - " + result.data.message);
		}
	};

	const handleSelectionChange = (buttonName, selectedSegment) => {
		console.log(`Selection changed for button '${buttonName}':`, selectedSegment);
		if(buttonName === 'groupBtn'){
			setCredentials({...credentials, group: selectedSegment});
		}
		else if(buttonName === 'usertypeBtn'){
			setCredentials({...credentials, usertype: selectedSegment});
		}
		
	  };

	return (
		<Modal isOpen={modal} toggle={() => setModal(!modal)}>
			<ModalHeader  className="custom-gray" toggle={() => setModal(!modal)}>
				Add New User
			</ModalHeader>
			<Form onSubmit={handleSubmit}>
				<ModalBody>
					<form>
						<div className="mb-3">
							<label htmlFor="name" className="form-label">
								Email:
							</label>
							<input
								type="email"
								className="form-control"
								id="email"
								placeholder="Email"
								value={credentials.email}
                                onChange={handleEmailChange}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="name" className="form-label">
								First and Last Name:
							</label>
							<input
								type="text"
								className="form-control"
								id="username"
								placeholder="Username"
								value={credentials.username}
                                onChange={(e) => {
                                    setCredentials({
                                        ...credentials,
                                        username: e.target.value,
                                    });
                                }}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="group" className="form-label">
								Group
							</label>
							<div>
							<SegmentedButton 
								name="groupBtn" 
								segments={['EXEC', 'SITE']} 
								defaultSelected="Site" 
								onSelect={handleSelectionChange} />
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="usertype" className="form-label">
								User Type
							</label>
							<div>
							<SegmentedButton 
								name="usertypeBtn" 
								segments={['ADMIN', 'USER']} 
								defaultSelected="User" 
								onSelect={handleSelectionChange} />
							</div>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<Button 
					color="primary" 
					type="submit" 
					className="custom-blue bordered-0"
					disabled={!areAllFieldsFilled()}>
						Save
					</Button>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
}
export default AddUserModal;
