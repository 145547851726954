import React, { useEffect,useState, useContext, useReducer } from "react";
import { Button, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import AppTable from "../components/AppTable";
//import { TechContext } from "../../context/TechContext";
//import { MachinesContext } from "../../context/MachinesContext";
//import { AssignmentsContext } from "../../context/AssignmentsContext";
import UserRow from "../components/UserRow";
import AddUserModal from "../components/AddUserModal";
import EditUserModal from "../components/EditUserModal";
import ConfirmationModal from "../components/ConfirmationModal";
import UserAssignmentModal from "../components/UserAssignmentModal";
import UserContext  from '../contexts/UserContext';
import {AdminContext} from "../contexts/AdminContext";
import { AssignmentContext } from "../contexts/AssignmentContext";
import '../css/ButtonStyle.css';

const headers = ["Name","Email", "UserType", "Group"];

const initialState = {
	addModal: false,
	editModal: false,
	confirmationModal: false,
	assignModal: false,
	selected: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case "ADD_USER_MODAL":
			return {
				...state,
				addModal: action.payload,
			};
		case "EDIT_USER_MODAL":
			return {
				...state,
				editModal: action.payload,
			};
		case "ASSIGN_MODAL":
			return {
				...state,
				assignModal: action.payload,
			};
		case "CONFIRMATION_MODAL":
			return {
				...state,
				confirmationModal: action.payload,
			};
		case "SELECTED":
			return {
				...state,
				selected: [...state.selected, action.payload],
			};
		case "SELECT_ALL":
			return {
				...state,
				selected: action.payload,
			};
		case "DESELECT_ALL":
			return {
				...state,
				selected: [],
			};
		case "DESELECT":
			return {
				...state,
				selected: state.selected.filter(
					(selection) => selection.email !== action.payload.email
				),
			};
		default:
			return state;
	}
};

function ConfigUserPage() {
	// useReducer for cleaner code
	const {launchDashboard} = React.useContext(UserContext);
	const {getUsers,users,sites, getSites, deleteUsers,clearSites} = React.useContext(AdminContext);
	const { setSiteAssignments } = useContext(AssignmentContext);
	const [state, dispatch] = useReducer(reducer, initialState);
	const [techUsers, setTechUsers] = useState([]);
	const [userIds, setUserIds] = useState([]);
	const [modalItems, setModalItems] = useState(["DFW", "BOS", "BWI", "ATL", "ORD", "CAK", "CVG", "DEN", "EWR", "IAD", "LAX", "MCI", "MCO", "PHX", "SEA",  "DEN", "EWR", "IAD", "LAX"]);
	
	const handleCheck = (e, item) => {
		switch (e.target.id) {
			case "select-all":
				if (e.target.checked) {
					dispatch({ type: "SELECT_ALL", payload: users });
				} else {
					dispatch({ type: "DESELECT_ALL" });
				}
				break;
			default:
				if (e.target.checked) {
					dispatch({ type: "SELECTED", payload: item });
					// setSelected([...selected, item]);
				} else if (!e.target.checked) {
					dispatch({ type: "DESELECT", payload: item });
					// setSelected(selected.filter((check) => check !== item));
				
			}

		}
	};

	const handleDelete = async () => {
		var toDelete = [];
		state.selected.forEach((user) => {
			toDelete.push(user.email);
		});
		console.log(
			"ConfigUserPage ~ handleDelete ~ toDelete:",
			toDelete
		);

		const customer = sessionStorage.getItem('customer').replace(/"/g, '');
		
		let params = {emails: toDelete, customer: customer};
        var result = deleteUsers(params);
		if (result) {
			dispatch({
				type: "CONFIRMATION_MODAL",
				payload: !state.confirmationModal,
			});
			dispatch({ type: "DESELECT_ALL", payload: state.selected[0] });
			getUsers();
		} else {
			toast.error("Failed to delete user");
		}
	};

	const launch= async(e) => {
		e.preventDefault();
        launchDashboard();
    }

	//call back of AddUserModal dialog
    const onAddUser = (value) => {
        console.log("onAddUser : email - " + value.email + " , userName - " + value.userName);
		//setTechUsers([...techUsers,value]);
		getUsers();
       
    }


	//call back of AddUserModal dialog
    const onEditUser = (value) => {
		dispatch({ type: "DESELECT_ALL" })
        console.log("onEditUser : email - " + value.email + " , userName - " + value.userName);
		getUsers();
    }

	

	const hasExecGroup = () => {
	for (let i = 0; i < state.selected.length; i++) {
		// Check if the group property of the current object is 'EXEC'
		if (state.selected[i].group === 'EXEC') {
		return true;
		}
	}
	return false;
	};

	const canDelete = () => {
		const loggedInUserEmail = sessionStorage.getItem('userEmail');
		for (let i = 0; i < state.selected.length; i++) {
			// Check if the any of selected member is currently logged in then don't allow to delete
			if (state.selected[i].email === loggedInUserEmail) {
			return false;
			}
		}
		return true;
		};

	const handleAssign = async () => {
		var userIds = {
			emails: state.selected.map((user) => user.email),
		};
		clearSites();
		setSiteAssignments(userIds);
		var res = await getSites(userIds.emails);
	//	dispatch({ type: "DESELECT_ALL" })
	};

	useEffect(() => {
       getUsers();
	   setTechUsers(users);
        },[]);


	return (
		<div className="container animate__animated animate__fadeIn">
			<ConfirmationModal
				modal={state.confirmationModal}
				setModal={() =>
					dispatch({
						type: "CONFIRMATION_MODAL",
						payload: !state.confirmationModal,
					})
				}
				title="Delete User"
				message="Are you sure you want to delete this user?"
				onConfirm={handleDelete}
			/>
			<AddUserModal
				modal={state.addModal}
				setModal={() =>
					dispatch({
						type: "ADD_USER_MODAL",
						payload: !state.addModal,
					})
				}
				onAddUser={onAddUser}
			/>
			<EditUserModal
				modal={state.editModal}
				setModal={() =>
					dispatch({
						type: "EDIT_USER_MODAL",
						payload: !state.editModal,
					})
				}
				data={state.selected[0] || {}}
				//resetSelected={() => dispatch({ type: "DESELECT_ALL" })}
				onEditUser={onEditUser}
			/>
			<UserAssignmentModal
				modal={state.assignModal}
				setModal={() =>
					dispatch({
						type: "ASSIGN_MODAL",
						payload: !state.assignModal,
					})
				}
				// data={modalItems}
				users={state.selected}
			/>
			<div className="mb-3">
				<Row className="mt-2">
					<Col className="col-8">
						<Button
							className="custom-blue me-2 fw-bold border-0"
							onClick={() =>
								dispatch({ type: "ADD_USER_MODAL", payload: !state.addModal })
							}>
							Add
						</Button>
						<Button
							className="me-2 fw-bold custom-blue border-0"
							onClick={() =>
								dispatch({ type: "EDIT_USER_MODAL", payload: !state.editModal })
							}
							disabled={(state.selected.length === 0 || state.selected.length > 1) ? true : false}>
							Edit
						</Button>
						<Button
							className="me-2 fw-bold custom-blue border-0"
							onClick={() =>
								dispatch({
									type: "CONFIRMATION_MODAL",
									payload: !state.confirmationModal,
								})
							}
								disabled={!canDelete() || state.selected.length === 0  ? true : false}>
							Delete
						</Button>
						<Button className="me-2 fw-bold custom-blue border-0" 
						onClick={() =>{
								handleAssign();
								dispatch({
									type: "ASSIGN_MODAL",
									payload: !state.assignModal
								});
								}
							}
							disabled={ hasExecGroup() || state.selected.length === 0  ? true : false}>
							Assign
						</Button>
						
						
					</Col>
					<Col  className="col-4 text-end">
					<Button className="me-2 fw-bold custom-blue border-0" 
						 onClick={launch}>
							Launch Dashboard
						</Button>
					</Col>
				</Row>
			</div>
			<AppTable
				headers={headers}
				array={users || []}
				selected={state.selected}
				RowComponent={UserRow}
				handleCheck={handleCheck}
			/>
		</div>
	);
}
export default ConfigUserPage;
