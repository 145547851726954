import React, { useState,useContext } from "react";
import { AiFillPlusSquare, AiFillMinusSquare } from "react-icons/ai";
import { CustomCheckbox } from "./CustomCheckbox";
import { AssignmentContext } from "../contexts/AssignmentContext";

//import Location from "./Location";

function CustomerSite({ props }) {
	const getCheckState = (state) => {
		let checkState;
		switch (state) {
			case 0:
				checkState = false;
				break;
			case 1:
				checkState = null;
				break;
			case 2:
				checkState = true;
				break;
			default:
				checkState = false;
				break;
		}
		return checkState;
	};


    // useContext
	const { setCheckedOrUncheckedSite, setSiteAssignments } = useContext(AssignmentContext);
	const dataArr = Object.entries(props).map((pr) => {
		return {
			id: pr.id,
			name: pr.name,
			checkState: pr.checkState,
			
		};
	});

	const [checked, setChecked] = useState(props.checked);
	const [showNested, setShowNested] = useState(false);

    function setCheckedOrUnchecked(check) {
		setChecked(check);
		determineCheckState(check);
	}

	function determineCheckState(targetCheck) {
		var site = {
			checked: targetCheck,
			id: props.id,
		};

		setCheckedOrUncheckedSite(site);
	}

	// setChecked can change state directly since it'll return t/f/null
	const handleChange = (check) => {
		setChecked(check);
	};

	const toggleNested = () => {
		setShowNested(!showNested);
	};

	return (
		<div>
			<div key={props.id}>
				<div>
					{!props.children && (
						<>
							<span className="ms-2"></span>
							<CustomCheckbox checked={checked} onChange={setCheckedOrUnchecked} />
							<span>{props.name}</span>
						</>
					)}
				
				</div>
			</div>
		</div>
	);
}
export default CustomerSite;
