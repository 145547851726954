import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import '../css/SegmentedButton.css'; // Import custom CSS file

function SegmentedButton({ name, segments,disableBtn, defaultSelected, onSelect }) {
  const [activeButton, setActiveButton] = useState(defaultSelected);

  const handleButtonClick = (segment) => {
    setActiveButton(segment);
    onSelect(name, segment);
  };

  return (
    <ToggleButtonGroup type="radio" name={`segmented-button-${name}`} value={activeButton}>
      {segments.map((segment, index) => (
        <ToggleButton 
          key={index} 
          variant={activeButton === segment ? "secondary" : "outline-secondary"} 
          value={segment} 
          disabled={disableBtn}
          onClick={() => handleButtonClick(segment)}
        >
          {segment}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default SegmentedButton;
