import React, { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import '../css/ButtonStyle.css';

// TODO: refactor to have less props, and work better with the UsersPage
function AppTable({ headers, array, RowComponent, handleCheck, selected }) {
	const [showInactive, setShowInactive] = useState(false);

	return (
		<>
			<Table bordered responsive >
				<thead>
					<tr className="text-center table-secondary">
						<th className="custom-gray">
							<input
								type="checkbox"
								id="select-all"
								onChange={handleCheck}></input>
						</th>
						{headers.map((header) => (
							<th className="custom-gray">{header}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{array.map((item) => {
                        	return (
								<RowComponent
									key={item.name}
									props={item}
									selected={selected}
									handleCheck={handleCheck}
								/>
							);
					})}
				</tbody>
			</Table>
			{/* conditional statement in className for tables that don't have an active/inactive status */}
			{array[0] && array[0].hasOwnProperty("active") && (
				<Button onClick={() => setShowInactive(!showInactive)}>
					{showInactive ? "Hide Inactive" : "Show Inactive"}
				</Button>
			)}
		</>
	);
}
export default AppTable;
