import React, { useEffect } from "react";

function UserRow({ handleCheck, props, selected }) {
	 const { name, userType, group } = props;

	const rowChecked = (e) => {
		handleCheck(e, props);
	};

	const isSelected = () => {
		return selected.includes(props);
	};

	useEffect(() => {
		isSelected();
	}, [selected]);

	return (
		<tr className="text-center align-middle">
			<td>
				<input type="checkbox" id={props.email} checked={isSelected()} onChange={rowChecked} />
			</td>
			<td>{props.userName}</td>
			<td>{props.email}</td>
			<td>{props.userType}</td>
			<td>{props.group}</td>
		</tr>
	);
}
export default UserRow;
