import logo from './logo.svg';
import './App.css';
import { NavMenu } from './components/NavMenu';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { useState, useEffect } from "react";
import { UserContextProvider } from './contexts/UserContext';
import { SessionTimeoutHandler } from './components/SessionTimeoutHandler';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from './Pages/LoginPage';
import Dashboards from './Pages/Dashboards';
import UpdatePasswordPage from './Pages/UpdatePasswordPage';
import AdminPage from './Pages/AdminPage';
import ConfigUserPage from './Pages/ConfigUserPage';
import AddUserModal from './components/AddUserModal';
import ChangeAdminModal from './components/ChangeAdminModal';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import SelectDashboard from './components/SelectDashboard';
import NewPassword from './components/NewPassword';
import ForgotPassword from './components/ForgotPassword';
import { AdminContextProvider } from './contexts/AdminContext';
import { AssignmentContextProvider } from './contexts/AssignmentContext';

export default function App() {
  const[isActive,setIsActive]=useState(true);
  const[isLogout,setLogout]=useState(false);

  return (
    <BrowserRouter>
    <UserContextProvider>
      <AdminContextProvider>
        <AssignmentContextProvider>
    <SessionTimeoutHandler 
    onActive={()=>{setIsActive(true)}} 
    onIdle={()=>{setIsActive(false)}}
    onLogout={()=>{setLogout(true)}}
    timeOutInterval={1800000}
    />
    <div>
       <NavMenu />
       <Routes>
        <Route path="/" element={<LoginPage />}/>

        <Route path="/login" element={<LoginPage />}>
          <Route path="selectdashboard" element={<SelectDashboard />}></Route>
          <Route exact path='newpassword' element={<NewPassword />}/>
          <Route exact path="users" element={<ConfigUserPage />} />
        </Route>

        <Route exact path="/users" element={<ConfigUserPage />} />
        <Route exact path="/dashboard" element={<Dashboards />}/>
        <Route exact path="/updatepassword" element={<UpdatePasswordPage />}/>
        
        <Route exact path='/adduser' element={<AddUserModal />}/>
        <Route exact path='/changeadmin' element={<ChangeAdminModal />}/>
        <Route exact path='/resetpassword' element={<ResetPasswordPage />}/>
        <Route exact path='/admin' element={<AdminPage />}/>

        <Route exact path='/forgotpassword' element={<ForgotPassword />}>
          <Route path="selectdashboard" element={<SelectDashboard />}></Route>
          <Route exact path='resetpassword' element={<ResetPasswordPage />}/>
        </Route>
          
      </Routes>
      <ToastContainer />
    </div>
    </AssignmentContextProvider>
    </AdminContextProvider>
    </UserContextProvider>
    </BrowserRouter>
    );
}

