import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Container,
	Card,
	CardBody, 
	CardHeader,
	Button,
} from "reactstrap";
import { Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginService from "../Services/LoginService";
import   UserContext  from '../contexts/UserContext';


function NewPassword() {
    const {reset, dispatch, session, clientId,email,updatePassword,isError, ...state } = React.useContext(UserContext);
	const { isLoggedIn,customer} = state;
	const [input, setInput] = useState({
        session: "",
        clientId: "",
        email: "",
        password: ""
    });

    useEffect( () => {
		setInput({...input, session: session, clientId: clientId, email: email});
    }, []); // Empty dependency array means this effect runs once after the initial render


	const navigate = useNavigate();
   
	const onCancel = () => {
		console.log("onCancel isloggedIn: " + isLoggedIn + ", customer: " + customer);
		dispatch({ type: "RESET"});
        navigate("/login");
      }

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("NewPassword: " + input.clientId + ", email:  " + input.email + ", password: " + input.password); 
		const fetchData = async () => {
            // get the data from the api
            const response = await updatePassword(input);
           
            console.log("fetchData newPassword ");
          }
        // call the function
         fetchData()
		 .then(() =>
			{
				if(isError === true){
					toast.error("Failed to update password");
				}
				else{
					toast.success("Password updated successfully, Please login using the new password");
					navigate("/login");
				}
				
			})
             // make sure to catch any error
             .catch(console.error);
			
		
	};

	return (
		<Container>
			<div className="animate__animated animate__fadeIn" >
					<Col sm={4} className="mx-auto">
							<Card style={{ maxHeight: "75%" }} className="h-75 overflow-auto">
								<CardHeader className="text-center custom-gray">
									<strong>New Password Required</strong>
								</CardHeader>
								<CardBody>
									<div className="text-center d-flex justify-content-center">
										<input
											className="mx-auto  text-center"
											type="password"
											placeholder="Enter new password"
											name="password"
											id="password"
											value={input.password}
											onChange={(e) => {
												setInput({
													...input,
													password: e.target.value,
												});
											}}
									/>
									</div>
								</CardBody>
							</Card>
						<Row className="my-3">
								<Col className="text-center">
									<Button 
									className="custom-blue"
									color="primary" 
									type="submit"
									min-width="10rem"
									onClick={handleSubmit}>
										OK
									</Button>
								</Col>
								<Col className="text-center">
									<Button 
									color="secondary"
									type="submit"
									onClick={onCancel}>
										Cancel
									</Button>
								</Col>
						</Row>
					</Col>
			</div>
		</Container>
		
	);
}
export default NewPassword;