
import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Card, CardHeader, Col, CardTitle } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import   UserContext  from '../contexts/UserContext';


function ResetPasswordPage() {
	

	const navigate = useNavigate();
	const {loginUser,  resetPassword, ...state } = React.useContext(UserContext);
	const { customer, isError, message } = state;
    const [formInput, setFormInput] = useState({
        email: "",
        confirmationcode: "",
        password: "",
        confirmpassword: "",
      });
      const [formError, setFormError] = useState({
        password: "",
        confirmPassword: "",
      });

      const [isValidEmail, setIsValidEmail] = useState(false);

	const handleEmailChange = (e) => {
	  const email = e.target.value;
      setFormInput({
        ...formInput,
        [e.target.name]: email,
      });
	  setIsValidEmail(validateEmail(email));
	};

	const validateEmail = (email) => {
		const regex = /([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
		return regex.test(email);
	};

    const handleUserInput = (name, value) => {
        setFormInput({
          ...formInput,
          [name]: value,
        });
      };

      const validateFormInput = (event) => {
        event.preventDefault();
        let inputError = {
          password: "",
          confirmPassword: "",
        };
        if (!formInput.password) {
            setFormError({
              ...inputError,
              password: "Password should not be empty",
            });
            return
          }
      
         
          if (formInput.confirmpassword !== formInput.password) {
            setFormError({
              ...inputError,
              confirmPassword: "Password and confirm password should be same",
            });
            return;
          }
      
          if (!formInput.password) {
            setFormError({
              ...inputError,
              password: "Password should not be empty",
            });
            return;
          }
      
		  console.log("#ResetPassword email: " + formInput.email + " , customer: " + sessionStorage.getItem('customer'));
          setFormError(inputError);
		  let params = {email: formInput.email, confirmationcode: formInput.confirmationcode, password: formInput.password, selectedCustomer: (custUrl !== null) ? custUrl : sessionStorage.getItem('customer')};

          const fetchData = async () => {
            // get the data from the api
            const response = await resetPassword(params);
           
            console.log("fetchData Reset Password");
          }
        // call the function
         fetchData()
             // make sure to catch any error
             .catch(console.error);
	  };    

	const forgotPassword = async (e) => {
		e.preventDefault();
        
			await resetPassword(formInput);
	}
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailUrl = searchParams.get('email');
  const custUrl = searchParams.get('cust');
  const codeUrl = searchParams.get('code');
  
    useEffect(() => {
        console.log("ResetPasswordPage query params: email-" + emailUrl + ", cust- " + custUrl + ", code- " + codeUrl );

		console.log("ResetPasswordPage - customer: " + customer + ", email: " + state.email + ", message: " + state.message);

        if (sessionStorage.getItem('firstResetLoadDone') === null) {
			// If it's the first load, set the flag in local storage to true and reload the page
			sessionStorage.setItem('firstResetLoadDone', 1);
			console.log('This is the initial load for Reset password page');
		} else {
		  console.log('This is Reset password refresh, login' );
		  navigate("/login");
		}
	
		if (isError) {
			console.error("LoginPage - Error: " + state.message);
            toast.error(state.message);
		}

		if(customer === null && custUrl === null) {
            toast.error("Customer is NULL, Please login ");
			navigate("/");
		}

       

        return () => {
            sessionStorage.removeItem('firstResetLoadDone');
          };
		
	
	}, [ isError,  message, navigate]);

    useEffect(() => {
        formInput.email = emailUrl;
        formInput.confirmationcode = codeUrl;
    },[]);

return (
    <div className="text-center d-flex justify-content-center">
        <Col sm={7} className="mx-auto">
            <Card className="w-50 mx-auto">
                <CardHeader className="text-center custom-gray">
                    <strong>Reset Forgotten Password</strong>
                </CardHeader>
                <Form className="mt-3" onSubmit={validateFormInput}>
                    <FormGroup row>
                        <Label sm={6} className="text-center">
                            <strong>Email:</strong>
                        </Label>
                        <Col sm={6}>
                            <Input
                                className="mx-auto w-80 text-center"
                                type="email"
                                placeholder="Enter email address"
                                name="email"
                                id="email"
                               defaultValue={emailUrl}
                                value={formInput.email}
                                onChange={handleEmailChange}
                            />
                        </Col>
                    </FormGroup>
                    <div>
                        <h5>Enter Confirmation Code</h5>
                        <h6>A confirmation code was sent to your email address,</h6>
                        <h6> please enter it below to reset your password</h6>
                    </div>
                    <FormGroup row>
                        <Label sm={6} className="text-center">
                            <strong>Confirmation Code:</strong>
                        </Label>
                        <Col sm={6}>
                            <Input
                            className="mx-auto w-75 text-center"
                            type="text"
                            placeholder="Enter confirmation code"
                            name="confirmationcode"
                            defaultValue={codeUrl}
                            id="confirmationcode"
                            value={formInput.confirmationcode}
                            onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                            }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={6} className="text-center">
                            <strong>New Password:</strong>
                        </Label>
                        <Col sm={6}>
                            <Input
                            className="mx-auto w-75 text-center"
                            type="password"
                            placeholder="Enter password"
                            name="password"
                            id="password"
                            value={formInput.password}
                            onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                            }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={6} className="text-center">
                            <strong>Confirmation Password:</strong>
                        </Label>
                        <Col sm={6}>
                            <Input
                            className="mx-auto w-75 text-center"
                            type="password"
                            placeholder="Enter confirmation password"
                            name="confirmpassword"
                            id="confirmpassword"
                            value={formInput.confirmpassword}
                            onChange={({ target }) => {
                                handleUserInput(target.name, target.value);
                            }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={5} className="mx-auto text-center" >
                        <Button
                            className="mx-auto mt-3 custom-blue"
                            type="submit"
                            size="sm"
                            disabled={formInput.password === "" 
                                        || formInput.email === "" 
                                        || !validateEmail(formInput.email)
                                        || formInput.confirmationcode === "" 
                                        || formInput.confirmpassword === ""
                                        || formInput.password !== formInput.confirmpassword}>
                            Done
                        </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </Card>
        </Col>
    </div>
    );
}
export default ResetPasswordPage;
