import React, { useState, useContext } from "react";
import CustomerSite from "./CustomerSite";

function Sites({ data }) {
	const getCheckState = (state) => {
		let checkState;
		switch (state) {
			case 0:
				checkState = false;
				break;
			case 1:
				checkState = null;
				break;
			case 2:
				checkState = true;
				break;
			default:
				checkState = false;
				break;
		}
		return checkState;
	};


    const dataArr = Object.entries(data).map(([key, value]) => {
        return {id: key,
        name: key, 
        checked: getCheckState(value)
        };
    });

	return (
		<div>
			{dataArr.map((item) => {
				return <CustomerSite props={item} />;
			})}
		</div>
	);
}
export default Sites;
