import React, { useContext, useState } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	ModalFooter,
	ListGroup,
	ListGroupItem,
} from "reactstrap";
import { AdminContext } from "../contexts/AdminContext";
import Sites from "../components/Sites";
import { AssignmentContext } from "../contexts/AssignmentContext";

function UserAssignmentModal({ modal, setModal, users }) {
	const { sites,  } = useContext(AdminContext);
	const { setSiteAssignmentsForUsers } = useContext(AssignmentContext);
	const handleSubmit = (e) => {
		e.preventDefault();
		setSiteAssignmentsForUsers();
		setModal(!modal);
	};

	return (
		<Modal isOpen={modal}  toggle={() => setModal(!modal)}>
			<ModalHeader  className="custom-gray" toggle={() => setModal(!setModal)}>
				<strong >Site Assignments</strong>
			</ModalHeader>
			<h5 className="text-center">Selected Users</h5>
			<div className="d-flex flex-wrap mx-auto border border-3 rounded">
				<ul>
					{users && users.map((user) => (
					<li key={user.email}>
						{user.email}
					</li>
					))}
				</ul>
			</div>
			<ModalBody>
				{sites && <Sites data={sites} />}
			</ModalBody>
			<ModalFooter>
				<Button onClick={() => setModal(!modal)}>Cancel</Button>
				<Button  className="custom-blue bordered-0" color="primary" onClick={handleSubmit}>
					Ok
				</Button>
			</ModalFooter>
		</Modal>
	);
}
export default UserAssignmentModal;
