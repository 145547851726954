import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginService from "../Services/LoginService";
import DashboardService from "../Services/DashboardService";

export const UserContext = React.createContext();

const ResponseStatus = Object.freeze({
	LoginFailed : 'LoginFailed',
		NewPasswordRequired : 'NewPasswordRequired',
		DashboardSelectionRequired : 'DashboardSelectionRequired',
		LoginSuccessfulAdmin : 'LoginSuccessfulAdmin',
		LoginSuccessfulUser : 'LoginSuccessfulUser'
  });


const userReducer = (state, action) => {
	switch (action.type) {
	
		case "LOGIN":
			return {
				...state,
				isAdmin : sessionStorage.getItem("adminemail") !== "",
				isError: false,
				isLoggedIn: true,
				loginResponse: action.payload.loginResponse,
				message: action.payload.errorMessage,
				email: action.payload.email,
				idToken: action.payload.idToken,
				session: action.payload.session,
				customer: action.payload.customer,
				dashboards: action.payload.availableDashboards,
			};
		case "LOGIN_ERROR":
			return {
				...state,
				isError: true,
				isLoggedIn: false,
				isAdmin:false,
				message: action.payload.errorMessage,
			};
		case "LOGOUT":
			return {
				...state,
				isError: false,
				isLoggedIn: false,
				isAdmin: false,
				message: "User logged out",
				customer: null,
				resetSuccess: false,
			};
		case "LOGOUT_ERROR":
			return {
				...state,
				isError: true,
				isLoggedIn: false,
				isAdmin: false,
				message: "Failed to Logout user",
				customer: null,
			};
		case "NEW_PASSWORD":
			return {
				...state,
				isError: false,
				isLoggedIn:true,
				isAdmin:false,
				loginResponse: action.payload.loginResponse,
				idToken: action.payload.idToken,
				message: action.payload.errorMessage,
				session: action.payload.session,
				clientId: action.payload.clientId,
				customer: action.payload.customer,
			};
		case "DASHBOARD_SELECTION":
			return {
				...state,
				isError: false,
				isLoggedIn:true,
				loginResponse: action.payload.loginResponse,
				idToken: action.payload.idToken,
				message: action.payload.errorMessage,
				customer: action.payload.customer,
				dashboards: action.payload.availableDashboards,
			};
		case "SET_CREDENTIALS":
			return{
				...state,
				email: action.payload.userEmail,
				password: action.payload.userPassword,
				customer: action.payload.customer,
			};
		case "SET_DASHBOARD_URL":
			return{
				...state,
				urlResponse: action.payload.urlResponse,
				dashboards: action.payload.availableDashboards,
				dashboardUrl: action.payload.dashboardUrl,
				message: action.payload.errorMessage,
			};
		case "CLEAR_DASHBOARD_URL":
			return{
				...state,
				urlResponse: "",
				dashboards: "",
				dashboardUrl: "",
				message: "",
			};
		case "SET_LOADING":
			return{
				...state,
				loading: action.payload
			};
		case "UPDATE_PASSWORD":
			return{
				...state,
				isError: false,
				isLoggedIn: false,
				isAdmin: false,
			};
		case "UPDATE_PASSWORD_ERROR":
			return{
				...state,
				isError: true
			};
		case "RESET_PASSWORD":
			return{
				...state,
				isError: false,
				isLoggedIn: false,
				isAdmin: false,
			};
		case "RESET_PASSWORD_ERROR":
			return{
				...state,
				isError: true,
				isLoggedIn: false,
				isAdmin: false,
			};
		case "FORGOT_PASSWORD":
			return{
				...state,
				isError: false,
				isLoggedIn:false,
				isAdmin:false,
				resetSuccess: true,
				fgpResponse: action.payload.forgotResponse,
				message: action.payload.errorMessage,
				customer: action.payload.customer,
				dashboards: action.payload.availableDashboards,
			};
		case "FORGOT_PASSWORD_ERROR":
			return{
				...state,
				isError: true,
				isLoggedIn:false,
				isAdmin:false,
				resetSuccess: false,
				fgpResponse: action.payload.forgotResponse,
				message: action.payload.errorMessage
			};
			case "RESET":
				return{
					loading: false,
					isError: false,
					isLoggedIn: false,
					isAdmin: false,
					loading: true,
					password: "",
					loginResponse: "",
					message: "",
					idToken: "",
					session: "",
					clientId: "",
					email: "",
					customer: "",
					dashboards: [],
					
			};
		default:
			return state;
	}
};


const initialState = {
isError: false,
isLoggedIn: false,
loading: true,
password: '',
loginResponse: '',
message: '',
idToken: '',
session: '',
clientId: '',
email: '',
customer: '',
dashboards: [],
message: '',
dashboardUrl:'',
customer: sessionStorage.getItem('customer') || null,
userEmail: sessionStorage.getItem('userEmail') || null,
adminEmail: sessionStorage.getItem('adminEmail') || null,
}

function saveIdToken(token) {
	sessionStorage.setItem("idToken", token);
}

function saveUserEmail(email) {
	sessionStorage.setItem("userEmail", email);
}

export const UserContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(userReducer, initialState);
	console.log('UserContext.userReducer isLoggedIn:', state.isLoggedIn);

	const navigate = useNavigate();
	
	function reset() {
		dispatch({ type: "RESET" });
	}
	
	async function loginUser(credentials) {
		sessionStorage.setItem("adminemail", ""); //initialize adminemail when user is logging in.
		dispatch({ type: "SET_CREDENTIALS", payload: credentials });
			await LoginService.loginUser(credentials)
			.then(response => {
				console.log("5. userContext response: " + JSON.stringify(response));
				console.log("5-1. userContext customer: " + JSON.stringify(response.data.customer));
				console.log("5-1. userContext idToken: " + JSON.stringify(response.data.idToken));
				console.log("5-2. userContext clientId: "+ JSON.stringify(response.data.clientId) );
				console.log("5-2. userContext session: "+ JSON.stringify(response.data.session) );
				console.log("5-3. userContext Response: " + JSON.stringify(response.data.loginResponse));
				sessionStorage.setItem("customer", JSON.stringify(response.data.customer));
				saveIdToken(response.data.idToken);
				saveUserEmail(response.data.email);
				switch (response.data.loginResponse){
					case 0:{
						dispatch({ type: "LOGIN_ERROR", payload: response.data  });
						toast.error(response.data.errorMessage);
						navigate("/login");
						return;
					}
					case 1:{
						//open new password modal
						dispatch({ type: "NEW_PASSWORD", payload: response.data });
						navigate("/login/newpassword");
						return;
					}
					case 2:{
						//open dashboard selection modal
						dispatch({ type: "DASHBOARD_SELECTION", payload: response.data });
						navigate("/login/selectdashboard");
						return;
					}
					case 3:
						case 4:
						{
							//LoginSuccessfulAdmin - show admin UI
							dispatch({ type: "LOGIN", payload: response.data });
							sessionStorage.setItem("adminemail", JSON.stringify(response.data.email));
							// navigate("/login/admin");
							navigate("/login/users");
							return;
						}
					case 5:
						{
							sessionStorage.setItem("adminemail", "");
							dispatch({ type: "LOGIN", payload: response.data });
							navigateToDashboardPage();
						//send dashboard url request
						var res = DashboardService.getDashboard({idToken: response.data.idToken, email: sessionStorage.getItem("userEmail"), customer: response.data.customer})
						.then(response => {
							processDashboardResponse(response);
							
						});
						return;
					}
					case 6:{
						//Reset password required for this user
						dispatch({ type: "LOGIN_ERROR", payload: response.data  });
					//	toast.error(response.data.errorMessage);
						//generate temporary password for this user
						var creds = {userEmail: response.data.email};
						handleResetPasswordException(creds);
					}
					default:{
						//open dashboard url
					}
				}
				return ;
			})
			.catch(errResponse => {
			console.log("6. userContext error: " + JSON.stringify(errResponse));
			if(errResponse.response.status === 401){
				console.log("7. userContext error: 401 Unauthorized" );

				dispatch({ type: "LOGIN_ERROR", payload: "401 Unauthorized " });
			}
			else{
			dispatch({ type: "LOGIN_ERROR", payload: "Bad Request" });
			}
			return errResponse;
			});
	
	}


	function processDashboardResponse(response) {
		console.log("5. userContext dashBoard: " + JSON.stringify(response));
		console.log("5-1. userContext dashBoard urlResponse: " + JSON.stringify(response.data.urlResponse));
		console.log("5-2. userContext dashBoard available dashboards: ");
		response.data.availableDashboards.forEach(str => console.log(str));
		console.log("5-3. userContext dashBoard DashboardUrl: " + JSON.stringify(response.data.dashboardUrl));
		dispatch({ type: "SET_LOADING", payload: false });;
		dispatch({ type: "SET_DASHBOARD_URL", payload: response.data });
		if(response.data.errorMessage.length > 0) 
		{
			
		}
	}
	
	async function handleResetPasswordException(credentials){
		//call forgotpassword api to generate a temporary password or code
		const response = await LoginService.forgotPassword(credentials)
		.then(response => {
			console.log("5. forgotPassword userContext result: " + JSON.stringify(response));
			dispatch({ type: "FORGOT_PASSWORD", payload: response });
			switch (response.data.forgotResponse){
				case 0:{
					dispatch({ type: "FORGOT_PASSWORD_ERROR", payload: response.data });
					toast.error("Failed to generate code for resetting password, please try again: " + response.data.errorMessage);
					navigate("/login");
					return;
				}
				case 1:{
					//open dashboard selection modal
					dispatch({ type: "DASHBOARD_SELECTION", payload: response.data });
					navigate("/selectdashboard");
					return;
				}
				case 2:{
					//temporary password sent, you will be prompt to change password when you login using the temporary password
					toast.info("Temporary password sent");
					dispatch({ type: "FORGOT_PASSWORD", payload: response });
					navigate("/login");
					return;
				}
				case 3:
					{
						//ConfirmationCodeSent
						toast.success("Confirmation code sent, please use it to login");
						dispatch({ type: "FORGOT_PASSWORD", payload: response });
						sessionStorage.setItem('customer', response.data.customer);
						navigate("/resetpassword");
					
					return;
				}
				default:{
					//open dashboard url
				}
			}
			return response;
		})
		.catch(errResponse => {
			console.log("6. forgotPassword userContext error: " + JSON.stringify(errResponse));
			console.log("7. userContext error message : " + JSON.stringify(errResponse.responseText));
			dispatch({ type: "FORGOT_PASSWORD_ERROR", payload: errResponse.message });
			return errResponse;
		});
	}
	//this is called from Admin User page
	async function launchDashboard() {
		navigateToDashboardPage();
		getEmbeddedDashboard();
	
	}

	function navigateToDashboardPage() {
		dispatch({ type: "CLEAR_DASHBOARD_URL", payload: null });
		dispatch({ type: "SET_LOADING", payload: true });
		navigate("/dashboard");
	}

	async function getEmbeddedDashboard() {
		const customer = sessionStorage.getItem('customer').replace(/"/g, '');
		//send dashboard url request
		var res = DashboardService.getDashboard({idToken: sessionStorage.getItem("idToken"), email: sessionStorage.getItem("userEmail"), customer: customer})
		.then(response => {
			processDashboardResponse(response);
			//handle expired token error
			if (response.data.errorMessage.indexOf("Token expired") !== -1) {
				// Define a regular expression to match Unix timestamps
				const regex = /\b\d+\b/g;
				// Extract Unix timestamps from the string
				const timestamps = response.data.errorMessage.match(regex);
				// Convert Unix timestamps to JavaScript Date objects
				const currentDate = new Date(timestamps[0] * 1000);
				const expirationDate = new Date(timestamps[1] * 1000);
				const tempMssg = "Token expired at " + currentDate.toLocaleTimeString() + ", it must be before " + expirationDate.toLocaleTimeString() + ". New login is required";
				//navigate to login page
				toast.error(tempMssg);
				navigate("/login");
			}
		});
	}

	async function changePassword(credentials) {
		
			const user = await LoginService.changePassword(credentials)
			.then(response => {
				console.log("5. changePassword userContext result: " + JSON.stringify(response));
				sessionStorage.setItem("userId", JSON.stringify(response.data.userId));
				dispatch({ type: "CHANGE_PASSWORD", payload: response });
				return response;
			})
			.catch(errResponse => {
			console.log("6. changePassword userContext error: " + JSON.stringify(errResponse));
			//console.log("7. userContext error message : " + JSON.stringify(errResponse.data.result));
			dispatch({ type: "CHANGE_PASSWORD_ERROR", payload: errResponse.data });
			return errResponse;
			});
	}

	async function resetPassword(credentials) {
		
		const user = await LoginService.resetPassword(credentials)
		.then(response => {
			console.log("5. resetPassword userContext result: " + JSON.stringify(response));
			dispatch({ type: "RESET_PASSWORD", payload: response });
			toast.success("Your password has been reset, please login using your new password.");
			navigate("/login");
		})
		.catch(errResponse => {
			console.log("6. resetPassword userContext error: " + JSON.stringify(errResponse));
			console.log("7. userContext error message : " + JSON.stringify(errResponse.responseText));
			dispatch({ type: "RESET_PASSWORD_ERROR", payload: errResponse.message });
			toast.error("Failed to reset your password has been reset, please try again.");

			return errResponse;
		});
	}

	async function updatePassword(credentials) {
		
		const response = await LoginService.updatePassword(credentials)
		.then(response => {
			console.log("5. updatePassword userContext result: " + JSON.stringify(response));
			dispatch({ type: "UPDATE_PASSWORD", payload: response });
			return response;
		})
		.catch(errResponse => {
			console.log("6. updatePassword userContext error: " + JSON.stringify(errResponse));
			console.log("7. userContext error message : " + JSON.stringify(errResponse.responseText));
			dispatch({ type: "UPDATE_PASSWORD_ERROR", payload: errResponse.message });
			return errResponse;
		});
	}

	async function forgotPassword(credentials) {
		
		dispatch({ type: "SET_CREDENTIALS", payload: credentials });
		if(credentials.customer !== undefined) {
			sessionStorage.setItem('customer', credentials.customer);
		}
		const response = await LoginService.forgotPassword(credentials)
		.then(response => {
			console.log("5. forgotPassword userContext result: " + JSON.stringify(response));
			dispatch({ type: "FORGOT_PASSWORD", payload: response });
			switch (response.data.forgotResponse){
				case 0:{
					dispatch({ type: "FORGOT_PASSWORD_ERROR", payload: response.data });
					toast.error("Forgot Password failed try again: " + response.data.errorMessage);
					navigate("/forgotpassword");
					return;
				}
				case 1:{
					//open dashboard selection modal
					dispatch({ type: "DASHBOARD_SELECTION", payload: response.data });
					navigate("/forgotpassword/selectdashboard");
					return;
				}
				case 2:{
					//temporary password sent, you will be prompt to change password when you login using the temporary password
					toast.info("Temporary password sent");
					dispatch({ type: "FORGOT_PASSWORD", payload: response });
					navigate("/login");
					return;
				}
				case 3:
					{
						//ConfirmationCodeSent
						toast.success("Confirmation code sent, please use it to login");
						dispatch({ type: "FORGOT_PASSWORD", payload: response });
						sessionStorage.setItem('customer', response.data.customer);
						navigate("/forgotpassword/resetpassword");
					
					return;
				}
				default:{
					//open dashboard url
				}
			}
			return response;
		})
		.catch(errResponse => {
			console.log("6. forgotPassword userContext error: " + JSON.stringify(errResponse));
			console.log("7. userContext error message : " + JSON.stringify(errResponse.responseText));
			dispatch({ type: "FORGOT_PASSWORD_ERROR", payload: errResponse.message });
			return errResponse;
		});
	}

	async function logoutUser(){
		sessionStorage.removeItem("customer");
		sessionStorage.removeItem("adminemail");
		sessionStorage.removeItem("idToken")
		dispatch({ type: "LOGOUT" });
		dispatch({ type: "CLEAR_DASHBOARD_URL"});
	}

	

	const value = { ...state, dispatch, loginUser, logoutUser,changePassword,resetPassword, updatePassword,forgotPassword, reset,launchDashboard, getEmbeddedDashboard , handleResetPasswordException};
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;



