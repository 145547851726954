import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button,
	Col,
	Row,
} from "reactstrap";
import SegmentedButton from "./SegmentedButton";
import AdminService from "../Services/AdminService";

//import { TechContext } from "../../context/TechContext";

function EditUserModal({ data, modal, setModal, onEditUser }) {

	const [credentials, setCredentials] = useState({
        email: "",
        customer:"",
        username: "",
		group:"",
		usertype:"",
      });

	const [userData, setUserData] = useState({
		...data,
	});

	const [btnState, setBtnState] = useState(false);

	//const { updateTechUser } = useContext(TechContext);

	const areAllFieldsFilled = () => {
		return credentials.email !== '' &&
			   credentials.username !== '' &&
			   credentials.group !== '' &&
			   credentials.usertype !== '';
	  };


	const handleEdit = (e) => {
		var data = { ...userData };
		// checked works differently than other inputs
		if (e.target.type === "checkbox") {
			data[e.target.id] = e.target.checked;
		} else {
			data[e.target.id] = e.target.value;
		}

		setCredentials(data);
		console.log(
			"🚀 ~ file: EditUserModal.js:20 ~ EditUserModal ~ userData:",
			userData
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setCredentials({...credentials, customer: sessionStorage.getItem('customer') });
		const customer = sessionStorage.getItem('customer').replace(/"/g, '');
		let params = {email: credentials.email, customer: customer, username: credentials.username,group: credentials.group, usertype: credentials.usertype};

		var result = await AdminService.editUser(params);
		console.log(
			"EditUserModal.js: handleSubmit ~ result:",
			result
		);
		if (result.data.success === true) {
			toast.success("User edited successfully");
			onEditUser({email: credentials.email, userName: credentials.username, group: credentials.group, userType: credentials.usertype});

			setModal(!modal);
		} else {
			toast.error("Failed to edit user - " + result.data.message);
		}
	};

	const handleSelectionChange = (buttonName, selectedSegment) => {
		console.log(`Selection changed for button '${buttonName}':`, selectedSegment);
		// Handle selection change logic here
		if(buttonName === 'groupBtn'){
			setCredentials({...credentials, group: selectedSegment});
			
		}
		else if(buttonName === 'usertypeBtn'){
			setCredentials({...credentials, usertype: selectedSegment});
		}
		setBtnState(true);
	  };

	useEffect(() => {
		setUserData({
			...data,
		});
		setCredentials({...credentials, usertype: data.userType, group: data.group, username: data.name, email: data.email});

	}, [data]);

	const toggle = () => setModal(!modal);
	return (
		<Modal isOpen={modal} toggle={() => setModal(!modal)}>
			<ModalHeader className="custom-gray" toggle={() => setModal(!modal)}>
				Edit existing user
			</ModalHeader>
			<form>
				<ModalBody>
					<div className="mb-3">
						<label htmlFor="name" className="form-label">
							First and Last Name:
						</label>
						<input
							type="text"
							className="form-control"
							id="username"
							placeholder="Username"
							value={userData.userName}
							disabled={true}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">
							Email address
						</label>
						<input
							type="email"
							className="form-control"
							id="email"
							placeholder="Email"
							defaultValue={userData.email}
							disabled={true}
						/>
					</div>
					<div className="mb-3">
							<label htmlFor="group" className="form-label">
								Group
							</label>
							<div>
							<SegmentedButton 
								name="groupBtn" 
								segments={['EXEC', 'SITE']} 
								defaultSelected={userData.group}
								selectedSegment = {userData.group}
								disableBtn={false}
								onSelect={handleSelectionChange} />
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="usertype" className="form-label">
								User Type
							</label>
							<div>
							<SegmentedButton 
								name="usertypeBtn" 
								segments={['ADMIN', 'USER']} 
								defaultSelected={userData.userType}
								selectedSegment = {userData.usertype}
								disableBtn={userData.email === sessionStorage.getItem('userEmail')}
								onSelect={handleSelectionChange} />
							</div>
						</div>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={handleSubmit} className="custom-blue bordered-0" disabled={!btnState}>
						Save
					</Button>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
}
export default EditUserModal;
