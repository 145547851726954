import React, { useState, useEffect } from "react";
import {
	Container,
	Card,
	CardBody, 
	CardHeader,
	Button,
} from "reactstrap";
import { Row, Col} from "react-bootstrap";
import { useNavigate,Outlet} from "react-router-dom";
import   UserContext  from '../contexts/UserContext';


function ForgotPassword() {
    const {email,forgotPassword,isError,isLoggedIn,loading, resetSuccess,reset, ...state } = React.useContext(UserContext);
    const [fgpassCredentials, setFgpassCredentials] = useState({
		userEmail: '',
		customer: '',
	});

	const navigate = useNavigate();

    useEffect( () => {
		if (sessionStorage.getItem('firstLoadDone') === null) {
			// If it's the first load, set the flag in local storage to true and reload the page
			sessionStorage.setItem('firstLoadDone', 1);
			console.log('This is the initial load');
		} else {
		  console.log('This is dashboard refresh, login' );
		  navigate("/login");
		}

		return () => {
      	sessionStorage.removeItem('firstLoadDone');
    	};
    }, []); // Empty dependency array means this effect runs once after the initial render

    const OnSelectionChange = (e) => {
		e.preventDefault();
		const email = e.target.value;
		setFgpassCredentials({...fgpassCredentials,userEmail : e.target.value});
	};
	
   
	const onCancel = () => {
        console.log("onCancel ");
        navigate("/login");
      }

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("ForgotPassword:  email:  " + fgpassCredentials.userEmail); 
		const fetchData = async () => {
            // get the data from the api
            const response = await forgotPassword(fgpassCredentials);
           
            console.log("fetchData forgotPassword ");
          }
        // call the function
         fetchData()
		// make sure to catch any error
		.catch(console.error);
		
	};

	return (
        <>
        {resetSuccess ? (
            <Outlet />
        ) : (
		<Container>
			<div className="animate__animated animate__fadeIn" >
				<Col sm={4} className="mx-auto">
							<Card style={{ maxHeight: "75%" }} className="h-75 overflow-auto">
							<CardHeader className="text-center custom-gray">
									<strong>Forgot Password?</strong>
								</CardHeader>
								<CardBody>
									<div className="text-center d-flex justify-content-center">
										<input
											className="mx-auto  text-center"
											type="email"
											placeholder="Enter Email Address"
											name="userEmail"
											id="userEmail"
											value={fgpassCredentials.userEmail}
											onChange={OnSelectionChange}
									/>
									</div>
								</CardBody>
							</Card>
						<Row className="my-3">
								<Col className="text-center">
									<Button 
									className="custom-blue"
									disabled={fgpassCredentials.userEmail === ''}
									type="submit"
									min-width="10rem"
									onClick={handleSubmit}>
										OK
									</Button>
								</Col>
								<Col className="text-center">
									<Button 
									color="secondary"
									type="submit"
									onClick={onCancel}>
										Cancel
									</Button>
								</Col>
						</Row>
					</Col>
			</div>
		</Container>)}
    </>
		
	);
}
export default ForgotPassword;